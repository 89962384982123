import React from 'react';

const Loader = () => {
    return <div className='loader'>
        <div className='skcube1 skcube'></div>
        <div className='skcube skcube'></div>
        <div className='skcube4 skcube'></div>
        <div className='skcube3 skcube'></div>
    </div>;
}

export default Loader;

import React, { createContext, useContext, useEffect, useState } from "react";
import { LoginDto, UserInfoDto } from "../models/loginModels";
import Loader from "../components/ui/Loader";
import { useServices } from "./useServices";

interface UserContextDto {
    userInfo: UserInfoDto;
    login: (dto: LoginDto) => Promise<void>;
    logout: () => Promise<void>;
    reloadUserInfo: () => Promise<void>;
}

const authContext = createContext<UserContextDto>(undefined);

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    if (auth.userInfo) {
        return <authContext.Provider value={auth}>{children}</authContext.Provider>;
    } else {
        return <Loader />;
    }
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth(): UserContextDto {
    const { loginService } = useServices();
    
    const [userInfo, setUserInfo] = useState<UserInfoDto>();

    const logout = async () => {
        await loginService.logout();
        await reloadUserInfo();
    };

    const reloadUserInfo = async () => {
        const result = await loginService.getUserInfo();
        setUserInfo(result);
    }

    useEffect(() => {
        (async () => await reloadUserInfo())();
    }, []);
    
    return {
        userInfo,
        login: (dto: LoginDto) => loginService.login(dto),
        logout,
        reloadUserInfo
    };
}

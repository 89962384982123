import { AbstractService } from "../utils/ApiClient";
import {
    DescribeBankOfCoresResponse,
    DownloadBankOfCoresSublicenseRequest,
    DownloadBankOfCoresSublicenseResponse,
    EditBankOfCoresSublicenseRequest,
    GenerateBankOfCoresSublicenseRequest,
    GenerateBankOfCoresSublicenseResponse,
    ToggleBankOfCoresSublicenseRequest
} from "../models/bankOfCoresModel";

export class BankOfCoresService extends AbstractService {
    
    async describeBankOfCores(): Promise<DescribeBankOfCoresResponse> {
        const response = await this
            .query<DescribeBankOfCoresResponse>("bank-of-cores/describe-bank-of-cores");

        return response.data;
    }

    async generateSublicense(createBankOfCoresSublicenseRequest: GenerateBankOfCoresSublicenseRequest): Promise<GenerateBankOfCoresSublicenseResponse> {
        const response = await this
            .post<GenerateBankOfCoresSublicenseRequest, GenerateBankOfCoresSublicenseResponse>(
                "bank-of-cores/generate-bank-of-cores-sublicense",
                createBankOfCoresSublicenseRequest);

        return response.data;
    }

    async editSublicense(editBankOfCoresSublicenseRequest: EditBankOfCoresSublicenseRequest): Promise<void> {
        const response = await this
            .post<EditBankOfCoresSublicenseRequest, void>(
                "bank-of-cores/edit-bank-of-cores-sublicense",
                editBankOfCoresSublicenseRequest);

        return response.data;
    }

    async downloadSublicense(createBankOfCoresSublicenseRequest: DownloadBankOfCoresSublicenseRequest): Promise<DownloadBankOfCoresSublicenseResponse> {
        const response = await this
            .post<DownloadBankOfCoresSublicenseRequest, DownloadBankOfCoresSublicenseResponse>(
                "bank-of-cores/download-bank-of-cores-sublicense",
                createBankOfCoresSublicenseRequest);

        return response.data;
    }

    async toggleSublicense(toggleBankOfCoresSublicenseRequest: ToggleBankOfCoresSublicenseRequest): Promise<void> {
        const response = await this
            .post<DownloadBankOfCoresSublicenseRequest, void>(
                "bank-of-cores/toggle-bank-of-cores-sublicense",
                toggleBankOfCoresSublicenseRequest);

        return response.data;
    }
}

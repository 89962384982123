import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React from "react";
import { ToggleAction } from "./SubliceneListItem";
import ButtonWithSpinner from "../../ui/ButtonWithSpinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';

interface Props {
    action: ToggleAction;
    sblicenseId: string;
    cancel: () => void;
    confirm: () => void;
    isTogglingSublicense: boolean;
}

const ToggleSublicenseConfirmation = ({ action, sblicenseId, cancel, confirm, isTogglingSublicense }: Props) => {
    return (
        <Modal isOpen={!!action} toggle={isTogglingSublicense ? () => {} : cancel}>
            <ModalHeader>
                Are you sure you want to <strong>{ action === "Enable" ? "enable" : "disable" }</strong> sublicense?
            </ModalHeader>
            <ModalBody>
                <pre>Id: {sblicenseId}</pre>
            </ModalBody>
            <ModalFooter>
                <Button onClick={cancel} disabled={isTogglingSublicense}>
                    Cancel
                </Button>{" "}
                <ButtonWithSpinner type="submit" color="primary" onClick={confirm}
                                   disabled={isTogglingSublicense} spinning={isTogglingSublicense}>
                    { action === "Enable" ? (
                        <span>
                            Enable
                        </span>
                    ) : (
                        <span>
                            Disable
                        </span>
                    )}
                </ButtonWithSpinner>
            </ModalFooter>
        </Modal>
    );
}

export default ToggleSublicenseConfirmation;

import moment from "moment";

const fromServerTime = date => moment.utc(date);
const fromServerToLocalTime = serverDate => fromServerTime(serverDate).local();

const formatMomentDate = (momentDate: moment.Moment, format = 'YYYY-MM-DD HH:mm') => {
    if (format === 'short') {
        format = 'YYYY-MM-DD';
    }

    return momentDate.format(format);
}

export const formatServerDate = (date, format = 'YYYY-MM-DD HH:mm'): string => {
    const localDate = fromServerToLocalTime(date);
    return formatMomentDate(localDate, format);
}

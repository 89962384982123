import * as React from "react";
import { createContext, useContext } from "react";
import { BankOfCoresService } from "../services/BankOfCoresService";
import { LoginService } from "../services/LoginService";

export interface ServicesContextDto {
    loginService: LoginService;
    bankOfCoresService: BankOfCoresService;
}

const servicesContext = createContext<ServicesContextDto>({
    loginService: new LoginService(),
    bankOfCoresService: new BankOfCoresService()
});

export function ServiceProvider(props: { services: ServicesContextDto; children: JSX.Element }) {
    return <servicesContext.Provider value={props.services}>{props.children}</servicesContext.Provider>;
}

export const useServices = () => {
    return useContext(servicesContext);
};

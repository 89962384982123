import React, { useEffect, useState } from "react";
import {
    BankOfCoresSublicenseResponseDto,
    BankOfCoresSublicenseStatus,
    EditBankOfCoresSublicenseRequest, ToggleBankOfCoresSublicenseRequest
} from "../../../models/bankOfCoresModel";
import SubliceneListItem from "./SubliceneListItem";
import { useInput } from "rooks";
import { Button } from "reactstrap";
import { downloadBlobAsFile } from "../../../utils/fileUtils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

const subliceStatusesInOrder: BankOfCoresSublicenseStatus[] = ["Active", "Created", "Canceled"];

interface Props {
    sublicenses: BankOfCoresSublicenseResponseDto[];
    downloadSublicense: (sublicenseId: string, sublicenseTag: string) => void;
    editSublicense: (editSublicenseRequest: Omit<EditBankOfCoresSublicenseRequest, "bankOfCoresKey">) => void;
    toggleSublicense: (editSublicenseRequest: ToggleBankOfCoresSublicenseRequest) => void;
    bankOfCoresKey: string;
    memoryRatio: number;
    minCoresPerSublicense: number;
    maxCoresPerSublicense: number;
    remainingCores: number;
    mainLicenseExpirationDate: string;
    disabled: boolean;
    isExpired: boolean;
    isDeveloperEdition: boolean;
}

const SubliceneList = ({
    sublicenses,
    downloadSublicense,
    editSublicense,
    toggleSublicense,
    bankOfCoresKey, 
    minCoresPerSublicense,
    maxCoresPerSublicense,    
    remainingCores,
    memoryRatio,
    mainLicenseExpirationDate,
    disabled,
    isExpired,
    isDeveloperEdition
}: Props) => {
    if (!sublicenses || sublicenses.length === 0) {
        return null;
    }

    const [filteredSublicens, setFilteredSublicens] = useState([]);
    const inputFilter = useInput("");

    useEffect(() => {
        sublicenses
            .sort((a, b) => {
                if (a.sublicenseStatus === b.sublicenseStatus) {
                    return new Date(b.lastModifiedAt).getTime() - new Date(a.lastModifiedAt).getTime();
                }
                return subliceStatusesInOrder.indexOf(a.sublicenseStatus) - subliceStatusesInOrder.indexOf(b.sublicenseStatus);
            });
    }, [sublicenses]);
    
    useEffect(() => {
        updateFilteredList();
    }, [sublicenses, inputFilter.value]);

    const updateFilteredList = () => {
        const resultList = sublicenses.filter((x) =>
            `${x.sublicenseId} ${x.tag} ${x.customerName} ${x.createdBy} ${x.expirationDate}`
                .toLowerCase()
                .includes(inputFilter.value.toLowerCase())
        );

        setFilteredSublicens(resultList);
    }
    
    const downloadCsv = () => {
        let fileContent =
            "Sublicense ID,Tag,Customer name,Status,Used cores,Used memory,Last used at," +
            "Last modified at,Activated at,Can be activated until,Created by,Expiration date\n";

        sublicenses.forEach((x) => {
            fileContent +=
                `${x.sublicenseId},${x.tag},${x.customerName || "N/A"},${x.sublicenseStatus},${x.usedCores},${x.usedMemory},${x.lastUsedAt || "N/A"},` +
                `${x.lastModifiedAt},${x.activatedAt || "N/A"},${x.sublicenseStatus === "Created" ? x.canBeActivatedUntil : "N/A"},${x.createdBy},${x.expirationDate}\n`;
        });

        const blob = new Blob([fileContent], { type: "text/csv" });
        const fileName = bankOfCoresKey + "_data.csv";
        downloadBlobAsFile(fileName, blob);
    }

    return (
        <div className="sublicenses-list">
            <div className="sublicenses-list-header">
                <h3>Sublicenses</h3>
                <div className="flex-horizontal export-cta">
                    <input
                        {...inputFilter}
                        className="margin-right-sm"
                        placeholder="Search (ID, Tag, Customer name, Created by, Expiration date)"
                    />
                    <Button onClick={downloadCsv}>
                        <FontAwesomeIcon icon={faFileArrowDown} className="margin-right-xxs"/> Export as CSV
                    </Button>
                </div>
            </div>
            { filteredSublicens.map((x: BankOfCoresSublicenseResponseDto) => (
                <SubliceneListItem
                    key={x.sublicenseId}
                    sublicense={x}
                    downloadSublicense={downloadSublicense}
                    toggleSublicense={toggleSublicense}
                    disabled={disabled}
                    editSublicense={editSublicense}
                    minCoresPerSublicense={minCoresPerSublicense}
                    maxCoresPerSublicense={maxCoresPerSublicense}
                    remainingCores={remainingCores}
                    memoryRatio={memoryRatio}
                    mainLicenseExpirationDate={mainLicenseExpirationDate}
                    isExpired={isExpired}
                    isDeveloperEdition={isDeveloperEdition}
                />
            ))}
        </div>
    );
}

export default SubliceneList;

import React, { FormEvent } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { Redirect, useHistory } from "react-router";
import { useInput } from "rooks";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightToBracket, faKey } from '@fortawesome/free-solid-svg-icons';

const LoginKeyPage = () => {
    const { userInfo } = useAuth();
    
    if (userInfo.isAuthenticated) {
        return <Redirect to="/isv/manage" />;
    }
    
    const history = useHistory();
    const inputKey = useInput("");
    
    const onLogin = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        history.push("/isv/login?key=" + inputKey.value);
    }
    
    return (
        <div className="login-page">
            <div className="panel">
                <FontAwesomeIcon icon={faKey} style={{ height: 50 }}/>
                <h1 className="mt-3">RavenDB Licenses Key Login</h1>
                <form className="login-form" onSubmit={onLogin}>
                    <input {...inputKey} className="login-input" placeholder="ISV Bank of cores key" required />
                    <Button className="login-button" type="submit" color="primary">
                        <FontAwesomeIcon icon={faArrowRightToBracket} className="margin-right-xxs" /> Login
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default LoginKeyPage;

import { AbstractService } from "../utils/ApiClient";
import { LoginDto, UserInfoDto } from "../models/loginModels";

export class LoginService extends AbstractService {
    
    async login(loginDto: LoginDto): Promise<void> {
        await this.post<LoginDto, void>("login", loginDto);
    }

    async getUserInfo(): Promise<UserInfoDto> {
        const response = await this.query<UserInfoDto>("user");
        return response.data;
    }

    async logout(): Promise<void> {
        await this.post("logout");
    }
}

import * as React from "react";
import { useAuth } from "../../../hooks/useAuth";
import { Redirect } from "react-router";
import { useInput } from "rooks";
import { Button } from "reactstrap";
import { FormEvent, useState } from "react";
import Loader from "../../ui/Loader";
import { useRouter } from "../../../hooks/useRouter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightToBracket, faEnvelope } from '@fortawesome/free-solid-svg-icons';

interface Router {
    key?: string;
}

const LoginEmailPage = () => {
    const { login, userInfo } = useAuth();
    
    if (userInfo.isAuthenticated) {
        return <Redirect to="/isv/manage" />;
    }
    
    const { query } = useRouter<Router>();
    
    if (query.key == null || query.key === "") {
        return <Redirect to="/isv" />;
    }
    
    const key = encodeURIComponent(query.key);
    const inputEmail = useInput("");
    const [isLoading, setIsLoading] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    
    
    const onLogin = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        
        try {
            await login({
                email: inputEmail.value,
                bankOfCoresKey: key
            });
            setIsEmailSent(true);
            
        } catch (e) {
            // nothing
        } finally {
            setIsLoading(false);
            setIsEmailSent(true);
        }
    }
    
    return (
        <div className="login-page">
            <div className="panel">
                <FontAwesomeIcon icon={faEnvelope} style={{ height: 50 }}/>
                <h1 className="mt-3">RavenDB Licenses Email Login</h1>
                <form className="login-form" onSubmit={onLogin}>
                    <input {...inputEmail} className="login-input" type="email" placeholder="E-mail" required disabled={isLoading} />
                    <Button className="login-button" type="submit" color="primary" disabled={isLoading}>
                        <FontAwesomeIcon icon={faArrowRightToBracket} className="margin-right-xxs"/> Login
                    </Button>
                </form>
                { isEmailSent && (
                    <div className="email-sent-success">
                        Email with sign-in link has been dispatched to the provided email address. Please check your inbox shortly.
                    </div>
                )}
            </div>
            { isLoading && <Loader /> }
        </div>
    );
}


export default LoginEmailPage;

import { useHistory, useLocation, useParams, useRouteMatch } from "react-router";
import { useMemo } from "react";
import { parse } from "qs";

export function useRouter<RouteParameters = {}>() {
    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();

    return useMemo(() => {
        return {
            push: history.push,
            replace: history.replace,
            pathname: location.pathname,
            query: {
                ...parse(location.search, { ignoreQueryPrefix: true }),
                ...params,
            } as RouteParameters,
            match,
            location,
            history,
        };
    }, [params, match, location, history]);
}

import * as React from "react";
import { useEffect, useRef } from "react";
import { useRouter } from "../../../hooks/useRouter";

interface Router {
    token?: string;
}

const LoginLinkPage = () => {
    const formRef = useRef<HTMLFormElement>();
    const { query } = useRouter<Router>();

    useEffect(() => {
        formRef.current.submit();
    }, []);

    return (
        <form
            ref={formRef}
            action={
                "/api/login/link" +
                "?token=" +
                encodeURIComponent(query.token)
            }
            method="post"
        >
        </form>
    );
}


export default LoginLinkPage;

import * as React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./NotFound";
import BankOfCoresPage from "./components/pages/bankOfCores/BankOfCoresPage";
import TopBar from "./components/pages/layout/TopBar";
import LoginKeyPage from "./components/pages/login/LoginKeyPage";
import LoginEmailPage from "./components/pages/login/LoginEmailPage";
import LoginLinkPage from "./components/pages/login/LoginLinkPage";
import {Redirect} from "react-router";

const App = () => {
    return (
        <TopBar>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/isv" />;
                </Route>
                <Route exact path="/isv" component={LoginKeyPage} />
                <Route exact path="/isv/manage" component={BankOfCoresPage} />
                <Route path="/isv/login" component={LoginEmailPage} />
                <Route path="/isv/login-link" component={LoginLinkPage} />
                <Route component={NotFound} />
            </Switch>
        </TopBar>
    );
}

export default App;

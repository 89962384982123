import { messagePublisher } from "./MessagePublisher";
import { SyntheticEvent } from "react";

function tryGuessContainerFromEvent(event: SyntheticEvent): HTMLElement {
    const target = event.currentTarget;
    return target.closest("[role=document]") || document.body;
}

class GeneralCopyToClipboard {
    static copy(toCopy: string, successMessage?: string, event?: SyntheticEvent);
    static copy(toCopy: string, successMessage?: string, container?: HTMLElement);
    static copy(
        toCopy: string,
        successMessage?: string,
        containerOrEvent: SyntheticEvent | HTMLElement = document.body
    ) {
        const container =
            containerOrEvent instanceof HTMLElement ? containerOrEvent : tryGuessContainerFromEvent(containerOrEvent);

        const dummy = document.createElement("textarea");
        // Add it to the document
        container.appendChild(dummy);
        try {
            dummy.value = toCopy;
            // Select it
            dummy.select();
            // Copy its contents
            const success = document.execCommand("copy");

            if (success) {
                if (successMessage) {
                    messagePublisher.reportSuccess(successMessage);
                }
            } else {
                messagePublisher.reportWarning("Unable to copy to clipboard");
            }
        } catch (err) {
            messagePublisher.reportWarning("Unable to copy to clipboard");
        } finally {
            // Remove it as its not needed anymore
            container.removeChild(dummy);
        }
    }
}

export const copyToClipboard = (value: string, label: string) => {
    GeneralCopyToClipboard.copy(value, `${label} has been copied to clipboard.`);
}

import * as React from "react";
import { Button } from "reactstrap";
import classNames from "classnames";

interface Props {
    spinning: boolean;
}

function ButtonWithSpinner(props: Props & Button["props"]) {
    const { className, children, spinning, ...otherProps } = props;
    const classes = classNames(className, { "btn-spinner": spinning }) || undefined;

    return (
        <Button className={classes} {...otherProps}>
            {children}
        </Button>
    );
}

export default ButtonWithSpinner;

import React, { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import {
    BankOfCoresSublicenseResponseDto,
    EditBankOfCoresSublicenseRequest,
    ToggleBankOfCoresSublicenseRequest
} from "../../../models/bankOfCoresModel";
import { Button, Form, UncontrolledTooltip } from "reactstrap";
import { formatServerDate } from "../../../utils/formatHelper";
import { useInput } from "rooks";
import ToggleSublicenseConfirmation from "./ToggleSublicenseConfirmation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faPenToSquare, faToggleOff, faToggleOn, faSave, faCancel, faInfoCircle, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "../../ui/DatePicker";
import moment from "moment";
import _ from "lodash";

export type ToggleAction = "Enable" | "Disable";

interface Props {
    sublicense: BankOfCoresSublicenseResponseDto;
    downloadSublicense: (sublicenseId: string, sublicenseTag: string) => void;
    editSublicense: (editSublicenseRequest: Omit<EditBankOfCoresSublicenseRequest, "bankOfCoresKey">) => void;
    toggleSublicense: (editSublicenseRequest: ToggleBankOfCoresSublicenseRequest) => void;
    memoryRatio: number;
    minCoresPerSublicense: number;
    maxCoresPerSublicense: number;
    remainingCores: number;
    mainLicenseExpirationDate: string;
    disabled: boolean;
    isExpired: boolean;
    isDeveloperEdition: boolean;
}

const SubliceneListItem = ({
    sublicense,
    downloadSublicense,
    editSublicense,
    toggleSublicense,
    minCoresPerSublicense,
    maxCoresPerSublicense,
    remainingCores,
    memoryRatio,
    mainLicenseExpirationDate,
    disabled,
    isExpired,
    isDeveloperEdition
}: Props) => {
    const [inEditMode, setInEditMode] = useState(false);
    const [toggleAction, setToggleAction] = useState<ToggleAction>(null);
    const [isTogglingSublicense, setIsTogglingSublicense] = useState(false);

    const [inputCoresCount, setInputCoresCount] = useState<number>(sublicense.usedCores);
    const inputCoresCountRef = useRef<HTMLInputElement>(null);
    const inputSublicenseTag = useInput(sublicense.tag);
    const inputSublicenseCustomerName = useInput(sublicense.customerName);
    const [inputExpirationDate, setInputExpirationDate] = useState<Date>(new Date(sublicense.expirationDate));

    const isCanceled = sublicense.sublicenseStatus === "Canceled";

    const expirationDateInfoId = useMemo(() => _.uniqueId("expiration-date-info-"), []);

    const onEditSublicense = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        await editSublicense({
            sublicenseId: sublicense.sublicenseId,
            cores: inputCoresCount,
            tag: inputSublicenseTag.value,
            customerName: inputSublicenseCustomerName.value,
            expirationDate: inputExpirationDate ? moment(inputExpirationDate).format("YYYY-MM-DD") : null
        });

        setInEditMode(false);
    };

    const onConfirmToggleSublicense = async () => {
        setIsTogglingSublicense(true);

        await toggleSublicense({
            sublicenseId: sublicense.sublicenseId,
            cancel: toggleAction === "Disable"
        });

        setToggleAction(null);
        setIsTogglingSublicense(false);
    }

    const onCancel = () => {
        setInputCoresCount(sublicense.usedCores);
        inputSublicenseTag.value = sublicense.tag;
        setInEditMode(false);
    }

    const getTooltipId = (prefix: string): string => `${prefix}-${sublicense.sublicenseId}`;
    
    const validateCores = () => {
        const input = inputCoresCountRef.current;
        const isShortageOfAvailableCores = sublicense.usedCores + remainingCores < minCoresPerSublicense;
        
        if (isShortageOfAvailableCores) {
            input.setCustomValidity(
                `It is not possible to change the 'Cores count'` + 
                ` because the 'Min cores per license' is ${minCoresPerSublicense},` + 
                ` and only ${remainingCores} cores are available.` + 
                " Please ensure there are enough available cores to proceed."
            )
        } else {
            // Empty string is used to set no validation errors
            input.setCustomValidity("");
        }

    }

    useEffect(() => {
        if (inputCoresCountRef.current === null) return;
        if (!isDeveloperEdition) return;
        validateCores();
    }, [inEditMode]);
    
    return (
        <div className={"sublicense-list-item sublicense-" + sublicense.sublicenseStatus.toLowerCase()}>
            { inEditMode ? (
                <>
                    <Form className="edit-sublicense-form" onSubmit={onEditSublicense}>
                        <div className="edit-form-cols">
                            <div>
                                <div className="property-name">ID</div>
                                <div className="property-value">{ sublicense.sublicenseId }</div>
                            </div>
                            <div>
                                <div className="property-name">Customer name</div>
                                <input
                                    {...inputSublicenseCustomerName}
                                    className="customer-name-input"
                                    type="text"
                                    disabled={disabled}
                                    placeholder="Customer name"
                                    maxLength={50}
                                />
                            </div>
                            <div className="edit-sublicense-cores">
                                <div className="property-name">Cores count</div>
                                <input
                                    onChange={(e) => setInputCoresCount(Number(e.target.value))}
                                    value={inputCoresCount}
                                    ref={inputCoresCountRef}
                                    className="cores-count-input"
                                    type="number"
                                    disabled={disabled}
                                    placeholder="Cores count"
                                    min={isDeveloperEdition ? minCoresPerSublicense ?? 1 : 1}
                                    max={
                                        isDeveloperEdition
                                            ? Math.min(...[maxCoresPerSublicense, sublicense.usedCores + remainingCores].filter(x => !!x))
                                            : sublicense.usedCores + remainingCores
                                    }
                                    required
                                />
                            </div>
                            <div className="edit-sublicense-tag">
                                <div className="property-name">Tag</div>
                                <input
                                    {...inputSublicenseTag}
                                    className="tag-input"
                                    type="text"
                                    disabled={disabled}
                                    placeholder="Sublicense tag"
                                    maxLength={50}
                                    required
                                />
                            </div>
                            <div>
                                <div className="property-name">
                                    <FontAwesomeIcon icon={faCircleInfo} id={expirationDateInfoId} />
                                    {" "}
                                    Expiration date
                                </div>
                                <UncontrolledTooltip target={expirationDateInfoId}>
                                    If you leave this field empty, it will be set to the expiration date of the main license.
                                </UncontrolledTooltip>
                                <DatePicker
                                    selected={inputExpirationDate}
                                    onChange={(x: Date) => setInputExpirationDate(x)}
                                    showYearDropdown
                                    showMonthDropdown
                                    minDate={new Date()}
                                    maxDate={new Date(mainLicenseExpirationDate)}
                                    disabled={disabled}
                                    placeholderText="Expiration date"
                                />
                            </div>
                        </div>
                        <div className="list-item-buttons">
                            <UncontrolledTooltip target={getTooltipId("save-button")}>
                                Save
                            </UncontrolledTooltip>
                            <Button color="primary" type="submit" disabled={disabled} id={getTooltipId("save-button")}>
                                <FontAwesomeIcon icon={faSave}/>
                            </Button>
                            <UncontrolledTooltip target={getTooltipId("cancel-button")}>
                                Cancel
                            </UncontrolledTooltip>
                            <Button onClick={onCancel} disabled={disabled} id={getTooltipId("cancel-button")}>
                                <FontAwesomeIcon icon={faCancel}/>
                            </Button>
                        </div>
                    </Form>
                </>
            ) : (
                <>
                    <div className="main-cols">
                        <div className="id-col">
                            <div className="property-name">ID</div>
                            <div className="property-value">{sublicense.sublicenseId}</div>
                        </div>
                        <div className="customer-name-col">
                            <div className="property-name">Customer name</div>
                            <div className="property-value">{
                                sublicense.customerName ?
                                    sublicense.customerName : <span>N/A</span>}
                            </div>
                        </div>
                        <div className="tag-col">
                            <div className="property-name">Tag</div>
                            <div className="property-value">{sublicense.tag}</div>
                        </div>
                        <div className="small-cols margin-top-xs">
                            <div className="used-cores-col">
                                <div className="property-name">Used cores</div>
                                <div className="property-value">{sublicense.usedCores}</div>
                            </div>
                            <div className="used-memory-col">
                                <UncontrolledTooltip target={getTooltipId("used-memory")}>
                                    Amount of sublicense RAM is based on the 1 cores
                                    : {roundMemoryRatio(memoryRatio)} GB RAM ratio
                                </UncontrolledTooltip>
                                <div className="property-name" id={getTooltipId("used-memory")}>
                                    <FontAwesomeIcon icon={faInfoCircle}/> Used memory
                                </div>
                                <div className="property-value">{sublicense.usedMemory + " GB"}</div>
                            </div>
                            <div className="status-col">
                                <div className="property-name">Status</div>
                                <div className="property-value">{sublicense.sublicenseStatus}</div>
                            </div>
                            <div className="activated-col">
                                <div className="property-name">Activated at</div>
                                <div className="property-value">
                                    {sublicense.activatedAt
                                        ? formatServerDate(sublicense.activatedAt)
                                        : <span>N/A</span>
                                    }
                                </div>
                            </div>
                            {sublicense.sublicenseStatus === "Created" && (
                                <div className="can-be-activated-until-col">
                                    <div className="property-name">Can be activated until</div>
                                    <div className="property-value">{formatServerDate(sublicense.canBeActivatedUntil)}</div>
                                </div>
                            )}
                            <div className="last-modified-col">
                                <div className="property-name">Last modified at</div>
                                <div className="property-value">{formatServerDate(sublicense.lastModifiedAt)}</div>
                            </div>
                            <div className="last-used-col">
                                <div className="property-name">Last used at</div>
                                <div className="property-value">
                                    {sublicense.lastUsedAt
                                        ? formatServerDate(sublicense.lastUsedAt)
                                        : <span>N/A</span>
                                    }
                                </div>
                            </div>
                            <div className="created-by-col">
                                <div className="property-name">Created by</div>
                                <div className="property-value">{sublicense.createdBy ? 
                                    sublicense.createdBy 
                                    : <span>N/A</span>}</div>
                            </div>
                            <div className="expiration-date-col">
                                <div className="property-name">Expiration date</div>
                                <div className="property-value">{formatServerDate(sublicense.expirationDate, "YYYY-MM-DD")}</div>
                            </div>
                        </div>
                    </div>

                    <div className="buttons-col list-item-buttons">
                        <UncontrolledTooltip target={getTooltipId("edit-button")}>
                            {isExpired ? "Your main license has expired" : "Edit"}
                        </UncontrolledTooltip>
                        <div id={getTooltipId("edit-button")} className="list-item-button">
                            <Button
                                onClick={() => setInEditMode(true)}
                                disabled={disabled || isCanceled || isExpired}
                            >
                                <FontAwesomeIcon icon={faPenToSquare}/>
                            </Button>
                        </div>

                        <UncontrolledTooltip target={getTooltipId("download-button")}>
                            Download
                        </UncontrolledTooltip>
                        <div id={getTooltipId("download-button")} className="list-item-button">
                            <Button
                                onClick={() => downloadSublicense(sublicense.sublicenseId, sublicense.tag)}
                                disabled={disabled || isCanceled}
                            >
                                <FontAwesomeIcon icon={faDownload}/>
                            </Button>
                        </div>

                        {isCanceled ? (
                            <>
                                <UncontrolledTooltip target={getTooltipId("enable-button")}>
                                    {isExpired ? "Your main license has expired" : "Enable"}
                                </UncontrolledTooltip>
                                <div id={getTooltipId("enable-button")} className="list-item-button">
                                    <Button
                                        onClick={() => setToggleAction("Enable")}
                                        disabled={disabled || isExpired}
                                        >
                                        <FontAwesomeIcon icon={faToggleOff}/>
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <UncontrolledTooltip target={getTooltipId("disable-button")} hidden={false}>
                                    {isExpired ? "Your main license has expired" : "Disable"}
                                </UncontrolledTooltip>
                                <div id={getTooltipId("disable-button")} className="list-item-button">
                                    <Button
                                        onClick={() => setToggleAction("Disable")}
                                        disabled={disabled || isExpired} 
                                    >
                                        <FontAwesomeIcon icon={faToggleOn} />
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}

            <ToggleSublicenseConfirmation
                action={toggleAction}
                sblicenseId={sublicense.sublicenseId}
                cancel={() => setToggleAction(null)}
                confirm={onConfirmToggleSublicense}
                isTogglingSublicense={isTogglingSublicense}
            />
        </div>
    );
}

const roundMemoryRatio = (x: number) => Math.round(x * 100) / 100;

export default SubliceneListItem;

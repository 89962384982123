import { messagePublisher } from "./MessagePublisher";

export const exceptionHandler = (exception: XMLHttpRequest, defaultMessage: string) => {
    
    if (exception.response.status === 400) {

        const messageJson = exception.response.data;
        
        if (typeof messageJson === "string") {
            messagePublisher.reportError(messageJson);
        } else {
            const errors = messageJson.errors ? messageJson.errors : messageJson;
            const messageJsonKeys = Object.keys(errors);
            const messages = messageJsonKeys.map(x => errors[x]);
            messagePublisher.reportError(messages.join(" "));
        }
    } else {
        messagePublisher.reportError(defaultMessage);
    }
}

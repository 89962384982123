import React from "react";
import { downloadBlobAsFile } from "../../../utils/fileUtils";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { GenerateBankOfCoresSublicenseResponse } from "../../../models/bankOfCoresModel";
import { copyToClipboard } from "../../../utils/CopyToClipboard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard, faDownload } from '@fortawesome/free-solid-svg-icons';

interface Props {
    generatedSublicenseDto: GenerateBankOfCoresSublicenseResponse;
    bankOfCoresKey: string;
    sublicenseTag: string;
    closeModal: () => void;
}

const GeneratedSublicenseModal = ({ generatedSublicenseDto, bankOfCoresKey, sublicenseTag, closeModal }: Props) => {
    
    if (!generatedSublicenseDto) {
        return null;
    }
    
    const jsonSublicense = JSON.stringify(JSON.parse(generatedSublicenseDto.sublicense), null, 2);

    const downloadSublicense = () => {
        const blob = new Blob([jsonSublicense], { type: "application/json" });

        const fileName = bankOfCoresKey + "_" + sublicenseTag;
        downloadBlobAsFile(fileName, blob);

        closeModal();
    }
    
    const copySublicense = () => {
        copyToClipboard(jsonSublicense, "Sublicense");
    }

    return (
        <Modal isOpen toggle={closeModal}>
            <ModalHeader toggle={closeModal}>
                Generated sublicense
            </ModalHeader>
            <ModalBody>
                <pre>{jsonSublicense}</pre>
            </ModalBody>
            <ModalFooter>
                <Button color="info" type="button" onClick={copySublicense}>
                    <FontAwesomeIcon icon={faClipboard} className="margin-right-xxs" /> Copy
                </Button>
                <Button color="primary" type="button" onClick={downloadSublicense}>
                    <FontAwesomeIcon icon={faDownload} className="margin-right-xxs" /> Download
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default GeneratedSublicenseModal;

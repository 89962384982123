import React, { ReactNode } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';

const logo = require("../../../../img/logo.svg");

interface Props {
    children: ReactNode;
}

const TopBar = ({ children }: Props) => {
    const { userInfo, logout } = useAuth();
    
    return (
        <>
            <div className="top-bar">
                <div>
                    <Link to="/">
                        <img className="logo" src={logo} alt="RavenDb logo"/>
                    </Link>
                </div>
                <div>
                    { userInfo.isAuthenticated ? (
                        <div className="flex-horizontal align-items-center">
                            <span className="margin-right">{ userInfo.email }</span>
                            <a onClick={logout} className="logout-link">
                                <FontAwesomeIcon icon={faArrowRightFromBracket} className="margin-right-xxs"/> Logout
                            </a>
                        </div>
                    ) : (
                        <Link to="/isv" className="logout-link">
                            <FontAwesomeIcon icon={faArrowRightToBracket} className="margin-right-xxs"/> Login
                        </Link>
                    )}
                </div>
            </div>
            
            { children }
        </>
    );
}

export default TopBar;

export function downloadBlobAsFile(fileName: string, blob: Blob) {
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");

    a.id = "#" + fileName;
    a.href = blobUrl;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.getElementById(a.id).remove();
}

import { Button, Modal, ModalFooter, ModalHeader } from "reactstrap";
import React from "react";
import ButtonWithSpinner from "../../ui/ButtonWithSpinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';

interface Props {
    show: boolean;
    coreCount: number;
    cancel: () => void;
    confirm: () => void;
    isGeneratingSublicense: boolean;
}

const GenerateSublicenseConfirmation = ({ show, coreCount, cancel, confirm, isGeneratingSublicense }: Props) => {
    return (
        <Modal isOpen={show} toggle={isGeneratingSublicense ? () => {} : cancel}>
            <ModalHeader>
                Are you sure you want to generate a license for <strong>{coreCount}</strong> core{coreCount > 1 ? "s" : ""}?
            </ModalHeader>
            <ModalFooter>
                <Button onClick={cancel} disabled={isGeneratingSublicense}>
                    Cancel
                </Button>{" "}
                <ButtonWithSpinner type="submit" color="primary" onClick={confirm}
                                   disabled={isGeneratingSublicense} spinning={isGeneratingSublicense}>
                    <FontAwesomeIcon icon={faPlus} className="margin-right-xxs"/> Generate
                </ButtonWithSpinner>
            </ModalFooter>
        </Modal>
    );
}

export default GenerateSublicenseConfirmation;

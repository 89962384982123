import "../styles/styles.scss";

import * as React from "react";
import { createRoot } from 'react-dom/client';

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ProvideAuth } from "./hooks/useAuth";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <ProvideAuth>
            <App />
        </ProvideAuth>
    </BrowserRouter>
);
